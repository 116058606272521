import axios from "axios";
import { URL, NEW_URL, createOrder } from "../../../api/requests";
import { axiosInstance } from "../../../api/config";
import { showErrorToast } from "../../../utils/utils";

//APPENDS THE RAZROPAY SCRIPT TO THE PAGE HTML BODY

export function loadRazorpay() {
  const script = document.createElement("script");
  script.src = "https://checkout.razorpay.com/v1/checkout.js";
  document.body.appendChild(script);
}

//SHOWS THE RAZORPAY MODAL
export async function showRazorpay(
  orderData = {},
  userObj,
  event,
  primaryBooker,
  guests,
  couponCode,
  callback
) {
  //TODO: Edit options.key for dev and production
  console.log(orderData, event, event.data.title, userObj.token);
  let paymentDiv = document.querySelector(".loader-booking-container");
  let showErrorMessagePay = document.querySelector(".show-error-message-pay");

  let paymentButton = document.querySelector(".payment");
  // paymentButton.disabled = true;
  // console.log("process.env.REACT_APP_RZP_KEY", process.env.REACT_APP_RZP_KEY);

  createOrder({
    eventId: event.data.eventId,
    couponCode: couponCode,
    primaryBooker: primaryBooker,
    guests: guests,
  }).then((orderData) => {
    console.log("orderData", orderData);
    if (orderData.status === "success") {
      const options = {
        key: process.env.REACT_APP_RZP_KEY,
        amount: orderData?.data?.amount,
        currency: "INR",
        name: "Playace.co",
        description: `${event.data.title} | ${event.data.eventId}`,
        // image: `${process.env.REACT_APP_BASE_URL}/static/media/logo.b76536f4.png`,
        order_id: orderData?.data?.id,
        handler: async function (response) {
          // paymentButton.value = "Hold On...";

          console.log("RAZOR response", response);
          // let paymentDetails = {
          // 	id : response.razorpay_payment_id,
          // 	amount : amount * 100,
          // 	currency : "INR"
          // }
          // try {
          // 	let capture = await capturePayment(paymentDetails,userObj);
          // 	if(capture.status === 'success'){
          // 		let addusers = await addGuestToUsers(guests, userObj);
          // 		if(addusers.status === 'success'){
          // 			paymentButton.disabled = false;
          // 			let store = await addParticipants(capture, userObj, guests, event, couponData);
          // 			if(store.status === 'success'){
          const queryParams = new URLSearchParams({
            paid: true,
            title: event?.data?.title,
            locationOg: event?.data?.location,
            city: event?.data?.city,
            eventDate: event?.data?.eventDate,
            partyImage: event?.data?.images?.[0],
          }).toString();
          window.location.href = `/?${queryParams}`;
          // 			}
          // 		}
          // 	}
          // } catch (error) {
          // 	console.log("error", error);
          // }
        },
        prefill: {
          name: userObj?.fullName,
          email: userObj?.email,
          contact: JSON.stringify(userObj?.phone),
        },
        theme: {
          color: "#FF1844",
        },
        modal: {
          ondismiss: function () {
            document.body.style.overflow = '';
            if (paymentDiv !== null) paymentDiv.style.display = "none";
            if (paymentButton != null) paymentButton.disabled = false;
          },
        },
      };
      let paymentObj = new window.Razorpay(options);
      paymentObj.open();
    } else {
      showErrorToast(orderData?.message ?? "Something went wrong");
      if (showErrorMessagePay) {
        showErrorMessagePay.innerHTML = orderData.error
          ? orderData.error
          : "Oops! Something went wrong. Try again later.";
        showErrorMessagePay.style.display = "block";
        setTimeout(() => {
          showErrorMessagePay.style.display = "none";
        }, 5000);
      }
    }
  }).catch(() => {

  }).finally(() => {
    callback()
  });
}

