import axios from "axios";
import { axiosInstance } from "./config";

export const auth = {
  headers: {
    Authorization:
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OTc1NywiaWF0IjoxNjM4MTgwMjExfQ.k7jPse58Jf0JksYMjnPbk5f1jl-Fw8gKYe3qUIGrf6U",
  },
};

export const defaulttoken =
  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OTc1NywiaWF0IjoxNjM4MTgwMjExfQ.k7jPse58Jf0JksYMjnPbk5f1jl-Fw8gKYe3qUIGrf6U";

export const URL = "https://api.playace.xyz/";

export const NEW_URL = process.env.REACT_APP_API_URL;

export const FIREBASE_KEY = process.env.REACT_APP_FIREBASE_KEY;

export const fetchPartyListing = async (key, limit, filter, usertoken) => {
  return axiosInstance.get("api/events");
  // const { data } = await axios.get(`${NEW_URL}api/events`, {

  // });
  // return data;
};

export const fetchPartyById = async (id) => {
  return axiosInstance.get(`/api/events/${id}`);
  const { data } = await axios.get(`${NEW_URL}api/events/${id}`, {});
  return data;
};

export const fetchEventsByHostId = async (id) => {
  return axiosInstance.get(`/api/events/byHost/${id}`);
  // const { data } = await axios.get(`${NEW_URL}api/events/byHost/${id}`, {
  // });
  // return data;
};
export const fetchGuestInEventsByHostId = async (usertoken, id, key) => {
  return axiosInstance.get(`${NEW_URL}api/participant/eventId/${id}`);

  const { data } = await axios.get(`${NEW_URL}api/participant/eventId/${id}`, {
    headers: {
      Authorization: `Bearer ${usertoken}`,
    },
  });

  return data;

  //   const dummyData = {
  //   	"message": "Events Details",
  //   	"status": "success",
  //   	"data": {
  //   		"eventId": "EVNT_1720452753103",
  //   		"title": "refund test 2",
  //   		"description": "test",
  //   		"host": "UID_1719296155803",
  //   		"rules": "test",
  //   		"location": "refund test 2 address",
  //   		"images": [
  //   			"https://beta-playace-files.s3.ap-south-1.amazonaws.com/playace-beta/events/partytest1.jpg"
  //   		],
  //   		"amount": 112,
  //   		"membersLimit": 3,
  //   		"eventDate": "2024-07-24",
  //   		"invitationEndDate": "2024-07-25",
  //   		"startTime": "05:00",
  //   		"endTime": "06:00",
  //   		"additionalInfo": [],
  //   		"status": "live",
  //   		"city": "Mumbai",
  //   		"amenities": [],
  //   		"hostName": "Ash testuser",
  //   		"hostProfileImage": "https://beta-playace-files.s3.ap-south-1.amazonaws.com/playace-beta/profiles/Screenshot%202024-06-27%20113312.png",
  //   		"event_reviews_count": [],
  //   		"acceptedParticipantsCount": 0,
  //   		"isParticipantAlreadyExist": false,
  //   		"isSoldOut": false,
  //   		"partiesHosted": 4
  //   	}
  //   }
  //   return dummyData;
};

export const fetchallGuestEventsbyPhone = async (phone) => {
  return axiosInstance.get(`api/participant/AttendedEvents/${phone}`);
};

// FETCH REVIEWS BY EVENT
export const fetchReviewsByEvent = async (eventId, offset, limit) => {
  const { data } = await axios.get(
    `${NEW_URL}api/eventReviews/event/${eventId}?offset=${offset}&limit=${limit}`,
    {}
  );
  return data;
};

// FETCH REVIEWS BY EVENT
export const fetchReviewsByHost = async (hostId, offset, limit) => {
  console.log("offset", offset, limit);

  // return {
  //   "message": "Event Reviews Details",
  //   "status": "success",
  //   "data": {
  //     "reviews": [
  //       {
  //         "_id": "65d3016fff5b5531c1badde1",
  //         "eventReviewId": "ER_1708327279684",
  //         "rating": 5,
  //         "comment": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
  //         "updatedAt": "2024-02-19T07:21:19.876Z",
  //         "userName": "Arun Natikar",
  //         "profileImage": "https://beta-playace-files.s3.ap-south-1.amazonaws.com/playace/profiles/bouy1.jfif"
  //       },
  //       {
  //         "_id": "65d30159ff5b5531c1baddde",
  //         "eventReviewId": "ER_1708327257692",
  //         "rating": 4,
  //         "comment": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
  //         "updatedAt": "2024-02-19T07:20:57.886Z",
  //         "userName": "Arun Natikar",
  //         "profileImage": "https://beta-playace-files.s3.ap-south-1.amazonaws.com/playace/profiles/bouy1.jfif"
  //       },
  //       {
  //         "_id": "65d30157ff5b5531c1badddb",
  //         "eventReviewId": "ER_1708327255244",
  //         "rating": 4,
  //         "comment": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
  //         "updatedAt": "2024-02-19T07:20:55.437Z",
  //         "userName": "Arun Natikar",
  //         "profileImage": "https://beta-playace-files.s3.ap-south-1.amazonaws.com/playace/profiles/bouy1.jfif"
  //       },
  //       {
  //         "_id": "65d30142ff5b5531c1baddd8",
  //         "eventReviewId": "ER_1708327234281",
  //         "rating": 5,
  //         "comment": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
  //         "updatedAt": "2024-02-19T07:20:34.473Z",
  //         "userName": "Arun Natikar",
  //         "profileImage": "https://beta-playace-files.s3.ap-south-1.amazonaws.com/playace/profiles/bouy1.jfif"
  //       },
  //     ],
  //     "totalCount": 15,
  //     "totalPages": 3,
  //     "ratingAverage": "4.3"
  //   }
  // }

  return axiosInstance.get(
    `api/eventReviews/host/${hostId}?page=${offset}&limit=${limit}`
  );
  const { data } = await axios.get(
    `${NEW_URL}api/eventReviews/host/${hostId}?page=${offset}&limit=${limit}`,
    {}
  );
  return data;
};

//update status
export const updateStatus = async (userData, participant, token) => {
  const requestBody = {
    participantId: participant.participantId,
    value: "Rejected",
    isAdmin: false,
  };

  try {
    const response = await axios.put(
      `${NEW_URL}api/participant/updateStatus`,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    window.location.reload();
  } catch (error) {
    console.error(error);
  }
};

export const getParticipantCountAndMembersLimit = async (usertoken, body) => {
  return axiosInstance.post(
    "/api/participant/getParticipantCountAndMembersLimit",
    body
  );
  const { data } = await axios.post(
    `${NEW_URL}api/participant/getParticipantCountAndMembersLimit`,
    body,
    {
      headers: {
        Authorization: `Bearer ${usertoken}`,
      },
    }
  );
  return data;
};

// export const cancelpaymentRefundParticipant = async (usertoken, body) => {
// 	const { data } = await axios.post(`${NEW_URL}api/participant/paymentRefundParticipant`, body, {
// 		headers: {
// 			Authorization: `Bearer ${usertoken}`,
// 		},
// 	});
// 	return data;
// };

// export const cancelParticipantWithoutRefund = async (usertoken, body) => {
// 	const { data } = await axios.post(`${NEW_URL}api/participant/cancelParticipantWithoutRefund`, body, {
// 		headers: {
// 			Authorization: `Bearer ${usertoken}`,
// 		},
// 	});
// 	return data;
// };

// export const cancelPaymentRefundParticipantHalf = async (usertoken, body) => {
// 	const { data } = await axios.post(`${NEW_URL}api/participant/paymentRefundParticipantHalf`, body, {
// 		headers: {
// 			Authorization: `Bearer ${usertoken}`,
// 		},
// 	});
// 	return data;
// };

export const cancelPaymentRefundByEvent = async (usertoken, body) => {
  const { data } = await axios.post(
    `${NEW_URL}api/participant/paymentRefundByEvent`,
    body,
    {
      headers: {
        Authorization: `Bearer ${usertoken}`,
      },
    }
  );
  return data;
};

export const cancelParty = async (body) => {
  return axiosInstance.post("/api/participant/cancel", body);
};

//CREATE EVENT REQUEST
export const createEventReview = async (usertoken, body) => {
  const { data } = await axios.post(`${NEW_URL}api/eventReviews`, body, {
    headers: {
      Authorization: `Bearer ${usertoken}`,
    },
  });
  return data;
};

//All events by date location

export const getAllEventsByDateAndLocation = async (body) => {
  return axiosInstance.post("/api/events/getAllEvents", body);
  const { data } = await axios.post(
    `${NEW_URL}api/events/getAllEvents`,
    body,
    {}
  );
  return data;
};

//All events by user favorites
export const getAllFavoriteEvents = async (usertoken, body) => {
  console.log("getAllFavoriteEvents", body);

  return axiosInstance.post("api/events/getFavouriteEvents", body);

  // const { data } = await axios.post(
  //   `${NEW_URL}api/events/getFavouriteEvents`,
  //   body,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${usertoken}`,
  //     },
  //   }
  // );
  // return data;
};

// FETCH EVENT PARTICIPANTS
export const fetchEventParticipantsByEventPhone = async (
  usertoken,
  eventId,
  phone
) => {
  const { data } = await axios.get(
    `${NEW_URL}api/participant/${eventId}/${phone}`,
    {
      headers: {
        Authorization: `Bearer ${usertoken}`,
      },
    }
  );
  return data;
};

export const fetchuserDatabyPhone = async (usertoken, phone) => {
  const { data } = await axios.get(`${NEW_URL}api/participant/${phone}`, {
    headers: {
      Authorization: `Bearer ${usertoken}`,
    },
  });
  return data;
};

//GET ALL SPACES
//TODO: ERROR HANDLING ON THE SCREEN
export function getAllProperties(setProperties) {
  axios
    .get(`${URL}v1/property/list`, auth)
    .then((response) => {
      setProperties(response.data.data);
    })
    .catch((errors) => {
      console.error(errors);
    });
}

export const fetchProperties = async (key, usertoken) => {
  // token);
  const { data } = await axios.get(`${URL}v1/property/list`, {
    headers: {
      Authorization: usertoken ? `Bearer ${usertoken}` : `${defaulttoken}`,
    },
  });
  return data.data;
};

//GET SPACE BY ID
export const fetchSpaceById = async (key, id) => {
  const { data } = await axios.get(`${URL}v1.2/property/${id}`, auth);
  return data.data;
};

//REQUEST TO ATTEND PARTY
export const requestToAttend = async (event) => {
  const { data } = await axios.post(
    `${URL}v1/event/attend/request`,
    {
      event_id: event.event_id,
      payment_gateway_id: "",
      payment_id: "",
      playace_code: null,
      user_guests: event.user_guests,
    },
    {
      headers: {
        Authorization: `Bearer ${event.user_token}`,
      },
    }
  );
  return data;
};

//UPCOMING PARTIES - host
export const fetchUpcomingPartiesAsHost = async (key, usertoken) => {
  // token);
  const { data } = await axios.get(`${URL}v1/parties/hosting`, {
    headers: {
      Authorization: `Bearer ${usertoken}`,
    },
  });
  return data;
};

//UPCOMING PARTIES - guest
export const fetchUpcomingPartiesAsGuest = async (key, usertoken) => {
  const { data } = await axios.get(`${URL}v1.2/parties/booked`, {
    headers: {
      Authorization: `Bearer ${usertoken}`,
    },
  });
  return data;
};

//FETCH DYNAMIC FORMS
export const fetchDynamicForms = async (type, usertoken) => {
  const { data } = await axios.get(`${NEW_URL}api/setting/${type}`, {
    headers: {
      Authorization: `Bearer ${usertoken}`,
    },
  });
  return data;
};

//FETCH CITIES
export const fetchNewCities = async (usertoken) => {
  const { data } = await axios.get(`${NEW_URL}api/setting/cities`, {
    headers: {
      Authorization: `Bearer ${usertoken}`,
    },
  });
  return data;
};

//FETCH STATES
export const fetchNewStates = async (usertoken) => {
  const { data } = await axios.get(`${NEW_URL}api/setting/states`, {
    headers: {
      Authorization: `Bearer ${usertoken}`,
    },
  });
  return data;
};

//FETCH USER
export const fetchUser = async (key, usertoken, userId) => {
  return axiosInstance.get(`/api/users/${userId}`);
  const { data } = await axios.get(`${NEW_URL}api/users/${userId}`, {
    headers: {
      Authorization: `Bearer ${usertoken}`,
    },
  });
  return data;
};

//CREATE EVENT REQUEST
export const createEventRequest = async (usertoken, body) => {
  return axiosInstance.post("api/eventsRequest", body);

  // const { data } = await axios.post(`${NEW_URL}api/eventsRequest`, body, {
  // 	headers: {
  // 		Authorization: `Bearer ${usertoken}`,
  // 	},
  // });
  // return data;
};

//CHANGE PROFILE PICTURE
export const updateProfileImage = async (usertoken, userId, url) => {
  const { data } = await axios.post(
    `${NEW_URL}api/users/profileImage/update`,
    {
      userId: userId,
      profileImage: url,
    },
    {
      headers: {
        Authorization: `Bearer ${usertoken}`,
      },
    }
  );
  return data;
};

//UPDATE USER DETAILS
export const updateUser = async (body, users, token) => {
  body.userId = users.userId;
  body.gender = users.gender;
  body.accountType = users.accountType;
  body.profileImage = users.profileImage;
  await axios
    .put(`${NEW_URL}api/users`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
      // window.location.reload();
    })
    .catch((errors) => {
      console.error(errors);
    });
};

//GET GUESTLIST FOR HOST PARTIES
export const fetchGuestlist = async (key, event_id, status, usertoken) => {
  const { data } = await axios.get(
    `${URL}v1.2/parties/guestlist?event_id=${event_id}&status=${status}`,
    {
      headers: {
        Authorization: `Bearer ${usertoken}`,
      },
    }
  );
  return data;
};

//FETCH BOOKED PROPERTIES
export const fetchPropertiesBooked = async (key, usertoken) => {
  const { data } = await axios.get(`${URL}v1/property/booked`, {
    headers: {
      Authorization: `Bearer ${usertoken}`,
    },
  });
  return data;
};

//FETCH PROPERTY CATEGORIES
export const fetchPropertyCategories = async (key, usertoken) => {
  // token);
  const { data } = await axios.get(`${URL}v1/property/category-list`, {
    headers: {
      Authorization: (usertoken && `Bearer ${usertoken}`) || defaulttoken,
    },
  });
  return data;
};

//SEND ENQUIRY
export const sendEnquiry = async (
  space,
  usertoken,
  enquiryData,
  setShowEnquiry,
  setShowModal
) => {
  axios
    .post(
      `${URL}v1/property/send-enquiry`,
      {
        property_id: space.id,
        property_name: space.name,
        property_rate: space.rate,
        ...enquiryData,
      },
      {
        headers: {
          Authorization: `Bearer ${usertoken}`,
        },
      }
    )
    .then((response) => {
      console.log(response.data);
      if (response.status === "success") {
        setShowModal(true);
        // setShowEnquiry(false);
        // window.location.href = `${process.env.REACT_APP_BASE_URL}`;
        // window.location.href = "http://localhost:3001/"
      } else {
        // setShowEnquiry(false);
        // window.location.href = `${process.env.REACT_APP_BASE_URL}`;
        // window.location.href = "http://localhost:3001/"
      }
    })
    .catch((errors) => {
      console.log(errors);
    });
};

export const postFavorite = async (eventId, usertoken, type) => {
  return axiosInstance.put("api/users/favorite", {
    eventId: eventId,
    type: type,
  });
  try {
    const response = await axios.put(
      `${NEW_URL}api/users/favorite`,
      {
        eventId: eventId,
        type: type,
      },
      {
        headers: {
          Authorization: `Bearer ${usertoken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

///////////////////////

//For image uploads
//////////////////////////////////////
export const uploadCoverImage = async (usertoken) => {
  axios
    .post(
      `${URL}v1/admins/events/image`,
      {},
      {
        headers: {
          Authorization: `Bearer ${usertoken}`,
        },
      }
    )
    .then((response) => {})
    .catch((errors) => {
      console.error(errors);
    });
};

//SEND HOST A PARTY REQUEST
//////////////////////////////////////////////////////
export const sendHostPartyRequest = async (usertoken, partyData) => {
  axios
    .post(`${URL}v1/host-party`, partyData, {
      headers: {
        Authorization: `Bearer ${usertoken}`,
      },
    })
    .then((response) => {})
    .catch((errors) => {
      console.error(errors);
    });
};

//GET BANK DETAILS
//////////////////////////////////////////////////////////////
export const getBankDetails = async (key, usertoken) => {
  const { data } = await axios.get(`${URL}v1/user/bank-details`, {
    headers: {
      Authorization: `Bearer ${usertoken}`,
    },
  });
  //console.log(data);
  return data;
};

//ADD NEW BANK DETAILS
///////////////////////////////////////////////////////////////
export const addNewBank = async (usertoken, bankData) => {
  // console.log(bankData);
  await axios
    .post(`${URL}v1/user/bank-details`, bankData, {
      headers: {
        Authorization: `Bearer ${usertoken}`,
      },
    })
    .then((response) => {
      console.log(response);
      window.location.reload();
    })
    .catch((errors) => {
      console.log(errors);
    });
};

//GENERATE SDK TOKEN FOR ONFIDO
//////////////////////////
export const postUserData = async (userData) => {
  const { data } = await axios.post(
    `${URL}v1/onfido/generate-sdk-token`,
    userData
  );
  return data;
};

export const generateSdkToken = async (userData) => {
  const { data } = await axios.post(
    `${NEW_URL}api/users/generate-sdkToken`,
    userData
  );
  return data;
};

//////////GENERATE REFERRAL LINK
export const generateReferralLink = async () => {
  return await axios
    .post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${FIREBASE_KEY}`,
      {
        longDynamicLink:
          "https://playace.page.link/?link=https://play.google.com/store/apps/details?id=co.playace.playace_android&apn=co.playace.playace_android&ofl=https://playace.co",
      }
    )
    .then(
      (response) =>
        // console.log(response.data);
        response.data.shortLink
    );
};

//////////////////FETCH BANNER ADS
export const fetchBannerAds = async () => {
  const { data } = await axios.get(`${URL}v1/banner-ads`, auth);
  return data.data;
};

//////////////////FETCH CITIES
export const fetchCities = async () => {
  const { data } = await axios.get(`${NEW_URL}api/setting/cities`, {});
  return data;
};

//SEND HOST A PARTY REQUEST
//////////////////////////////////////////////////////
export const sendPartyRequest = async (partyData) => {
  axios
    .post(`${URL}v1/host-party-request`, partyData, auth)
    .then((response) => {})
    .catch((errors) => {
      console.error(errors);
    });
};

//APPLY COUPON CODE
//////////////////////////////////////////////////////
export const applyCouponCode = async (defaulttoken, partyData) => {
  try {
    const options = {
      method: "POST",
      data: partyData,
      headers: {
        Authorization: `Bearer ${defaulttoken}`,
      },
    };

    const response = await axios(
      `${NEW_URL}api/setting/coupons/valid`,
      options
    );
    return response;
  } catch (error) {
    console.error("Invalid coupon code:", error);
    throw error;
  }
};

//////////////////FETCH USER STATS
export const fetchUserStats = async (key, usertoken, userId) => {
  const { data } = await axios.get(`${NEW_URL}api/users/user-stats/${userId}`, {
    headers: {
      Authorization: `Bearer ${usertoken}`,
    },
  });
  return data.data;
};

//////////////////FETCH USER STATS
export const fetchHostingRequests = async (key, usertoken) => {
  const { data } = await axios.get(`${URL}v1/event/hosting-requests?status=0`, {
    headers: {
      Authorization: `Bearer ${usertoken}`,
    },
  });
  return data.data;
};

//////////////////FETCH EVENTS
export const fetchEventInfo = async (key) => {
  const { data } = await axios.get(`${URL}/v1/event/list/1`, {
    headers: {
      Authorization: `Bearer ${defaulttoken}`,
    },
  });
  return data;
};

//Profile module
export const fetchProfile = (async) => {
  return axiosInstance.get("api/users/profile");
};

export const getInterests = (async) => {
  return axiosInstance.get("api/users/interests");
};

export const updateInterests = async (interests) => {
  return axiosInstance.put("api/users/interests", { interests });
};

export const updateAboutSection = async (about) => {
  return axiosInstance.put("api/users/about", about);
};

export const verifyUser = async (data) => {
  return axiosInstance.post("api/onboard/verifyUser", data);
};

export const signupUser = async (data) => {
  return axiosInstance.post("api/onboard/signUp", data);
};

export const getProfileEvents = async (data) => {
  return axiosInstance.post("api/users/profileEvents", data);
};

export const getCities = async () => {
  return axiosInstance.get("/api/setting/cities");
};

export const getQuotation = async (data) => {
  return axiosInstance.post("/api/participant/quotation", data);
};

export const getAccounts = async () => {
  return axiosInstance.get("/api/accounts");
};

export const sendOtpWhatsapp = async (phone) => {
  return axiosInstance.post("/api/onboard/sendOtp", phone);
};

export const getAdBanners = async (isEvent = true) => {
  return axiosInstance.get(`/api/adBanners?isEvent=${isEvent}`);
};

export const sdkCheckAPI = async (data) => {
  return axiosInstance.post(`/api/users/sdk-check`, data);
};

export const getRefundPercentage = async (eventId) => {
  return axiosInstance.get(`/api/participant/refundDetails/${eventId}`);
};
export const getRegularCoupons = (data) => {
  return axiosInstance.post(`api/setting/coupons`, data);
};

export const createOrder = async (data) => {
  return axiosInstance.post(`/api/participant/create-order`, data);
};

export const subscribeNewsLetter = async (data) => {
  return axiosInstance.post(`/api/newsletters/createNewsLetter`, data);
};

export const contactUs = async (data) => {
  return axiosInstance.post("api/users/contactus", data);
};
