import React, { Suspense, useState, useEffect, lazy, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactPixel from "react-facebook-pixel";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faStar } from "@fortawesome/free-solid-svg-icons";
// import axios from 'axios';
import Modal from "react-bootstrap/Modal";
// import CTA from "../Home/CTA";
import Attend from "../Home/Attend";
import { PhotoGrid } from "../../modules/common";
import BookTicket from "../../modules/common/parties/BookTicket";
import { Default, Mobile } from "../../helpers/Breakpoints";
import { RenderUserObj, RenderDate, Loader } from "../../helpers";
import {
  fetchEventParticipantsByEventPhone,
  fetchEventsByHostId,
  fetchGuestInEventsByHostId,
  fetchPartyById,
  fetchReviewsByHost,
  fetchUser,
  fetchuserDatabyPhone,
  getHostReviews,
  updateStatus,
} from "../../../api/requests";

import "primeicons/primeicons.css";

import AHE from "../../assets/img/attend host earn.png";

import About from "../../modules/content/EventDetails/About";
// import Amenities from "./Amenities";
import Reviews from "../../modules/content/EventDetails/Reviews";
import Expander from "../../modules/content/EventDetails/Expander";
import Select from "react-select";

import { useDispatch, useSelector } from "react-redux";

import { useParams, Link, useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EventHostImages from "../Home/eventHostImages";
import DefaultDP from "../../../assets/DP.png";
import partyImage1 from "../../../assets/Rectangle 21.png";
import partyImage2 from "../../../assets/Rectangle 22.png";
import partyImage3 from "../../../assets/Rectangle 23.png";
import soldOut from "../../../assets/soldOut.png";
import "./PartiesDetail.css";
import {
  updateGuestCount,
  updatePromoCode,
} from "../../../actions/guestBookActions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { error } from "jquery";
import { formatTime, getLoader, showStarRating } from "../../../utils/utils";
import AdBanners from "../Home/AdBanners";
import NotFoundPage from "../404";

const Amenities = lazy(() => import("./Amenities"));

const apiRequest = {
  page: 1,
  limit: 4,
};

export default function PartiesDetail(props) {
  const [activeTab, setActiveTab] = useState("guestDetails");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const tabListStyle = {
    display: "flex",
    cursor: "pointer",
    marginTop: "65px",
  };

  const tabStyle = {
    padding: "10px 20px",
    borderBottom: "2px solid transparent",
    margin: "0 5px",
    transition: "background-color 0.3s",
  };

  const activeTabStyle = {
    ...tabStyle,
    borderBottom: "2px solid white",
    backgroundColor: "#111216",
  };

  const tabContentStyle = {
    padding: "20px",
  };

  const { id } = useParams();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isBookClicked = useSelector((state) => state.auth.bookClicked);

  let userObj = useSelector((state) => state.auth.data);
  userObj = isAuthenticated ? JSON.parse(userObj) : [];
  const [currentPage, setCurrentPage] = useState(1);

  const [eventParticipantByUser, setEventParticipantByUser] = useState([]);
  const [statusFilter, setStatusFilter] = useState("All");
  const [evnetHostName, setEvnetHostName] = useState([]);
  const [selectedHostName, setSelectedHostName] = useState("");
  const [evnetHostStatus, setEvnetHostStatus] = useState("");
  const [statusCounts, setStatusCounts] = useState(0);
  const [rejectCoun, setRejectCoun] = useState(0);
  const [pendingCoun, setPendingCoun] = useState(0);
  const [refundCoun, setRefundCoun] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [partyNotFount, setPartyNotFound] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const bookTicketRef = useRef();
  const [reviewData, setReviewList] = useState({
    reviews: [],
    totalCount: 0,
    totalPages: 0,
    ratingAverage: 0,
  });

  const [allReviewData, setAllReviewData] = useState({
    reviews: [],
    totalCount: 0,
    totalPages: 0,
    ratingAverage: 0,
  });
  const location = useLocation();
  // const [userDataForIm,setUserDataForIm] = useState([])
  const history = useHistory();
  const dispatch = useDispatch();
  const [reviewModal, showReviewModal] = useState(false);

  const [showMore, setShowMore] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const handleClose = (isLoggedIn = false) => {
    showReviewModal(false);
  };

  const handleGoBack = () => {
    history.goBack(); // Go back to the previous page in history
  };

  const itemsPerPage = 10;
  const imageStyle = {
    height: " 20vh !important",
  };

  const tableHeaderStyle = {
    backgroundColor: "#1E2022",
    padding: "16px 8px",
    textAlign: "center",
    fontFamily: "Kumbh Sans, 'sans-serif'",
  };

  const tableCellStyle = {
    borderBottom: "1px solid #333739",
    padding: "16px 8px",
    textAlign: "center",
  };
  const buttonStyle = {
    backgroundColor: "#ff1844",
    border: "none",
    color: "white",
    padding: "8px 12px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "14px",
    borderRadius: "4px",
  };

  const closeButtonStyle = {
    backgroundColor: "#808080",
    border: "none",
    color: "white",
    padding: "8px 12px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "14px",
    borderRadius: "4px",
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (props.match) {
      // window.location.reload();
    }
    ReactPixel.init("1890035134379309");
    ReactPixel.track("PageView");

    // let userDataForImage = fetchuserDatabyPhone(userObj.token, userObj.phone).then(
    //   (response) => {
    //     setUserDataForIm(response);
    //   }
    // )
  }, []);

  const callReviewApi = async (hostId, showAllReview = false) => {
    try {
      const response = await fetchReviewsByHost(
        hostId,
        apiRequest.page,
        apiRequest.limit
      );
      if (showAllReview) {
        setAllReviewData((prevState) => ({
          reviews: [...prevState.reviews, ...response?.data?.reviews],
          totalCount: response.data.totalCount,
          totalPages: response.data.totalPages,
          ratingAverage: response.data.ratingAverage,
        }));
      } else {
        setReviewList(response.data);
      }
      console.log(
        "response?.data?.totalPages",
        response?.data?.totalPages,
        apiRequest.page
      );

      if (response?.data?.totalPages > apiRequest.page) {
        setShowMore(true);
      } else {
        setShowMore(false);
      }
    } catch {}
  };

  useEffect(() => {
    callFetchPartyApi();
    const clearState = location?.state?.clearBookingState ?? false;
    if (clearState) {
      dispatch(updateGuestCount(1));
      dispatch(updatePromoCode(""));
      window.history.replaceState({}, "");
    }
  }, [id]);

  const callFetchPartyApi = async () => {
    try {
      setIsLoading(true);
      const res = await fetchPartyById(id);
      if (res.status === "success") {
        setData(res);

        if (userObj?.userId === res?.data?.host) {
          fetchGuestInEventsByHostId(userObj.token, id).then((item) => {
            const allparticipants = item.data.filter((participant) => {
              return participant.transaction.status === "captured";
            });

            setEventParticipantByUser(allparticipants);
            const dropdownOptions = item.data.map((option) => ({
              label: option.participant.fullName,
              value: option.participant.fullName,
            }));

            setEvnetHostName(dropdownOptions);

            let status = ["Accepted", "Rejected", "Pending", "Refund"];

            // const dropdownOptionsStatus = item.data.map((option) => ({
            //   label: option.participant.status,
            //   value: option.participant.status,
            // }));
            // setEvnetHostStatus(dropdownOptionsStatus);
            const getAllUniqueStatuses = () => {
              const uniqueStatuses = new Set(status);
              return Array.from(uniqueStatuses);
            };

            const uniqueStatuses = getAllUniqueStatuses();

            const dropdownOptionsStatus = uniqueStatuses.map((status) => ({
              label: status,
              value: status,
            }));

            setEvnetHostStatus(dropdownOptionsStatus);
          });
        }

        console.log("res", res);
        apiRequest.page = 1;
        apiRequest.limit = 4;
        callReviewApi(res.data.host);
      } else {
        setPartyNotFound(true);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // fetchEventsByHostId("fetchEventsByHostId", data?.data?.host, null)
    //   .then((response) => {
    //     setHostData(response?.data.length);
    //     console.log(response?.data, "response?.data.length");
    //   })
    //   .catch((error) => {});

    const acceptedParticipants = eventParticipantByUser.filter(
      (participant) =>
        participant.participant.status === "Accepted" &&
        participant.transaction.status === "captured"
    );
    const acceptedCount = acceptedParticipants.length;

    setStatusCounts(acceptedCount);

    const RejectdParticipants = eventParticipantByUser.filter(
      (participant) =>
        participant.participant.status === "Rejected" &&
        participant.transaction.status === "captured"
    );
    const RejectCound = RejectdParticipants.length;

    setRejectCoun(RejectCound);

    const PendingParticipants = eventParticipantByUser.filter(
      (participant) =>
        participant.participant.status === "Pending" &&
        participant.transaction.status === "captured"
    );
    const PendingCound = PendingParticipants.length;

    setPendingCoun(PendingCound);

    const RefundParticipants = eventParticipantByUser.filter(
      (participant) => participant.participant.status === "Refund"
    );
    const RefundCound = RefundParticipants.length;

    setRefundCoun(RefundCound);
  }, [userObj]);

  const showConfirmationDialog = (participant) => {
    setSelectedParticipant(participant);
    setShowConfirmation(true);
  };

  const handleUpdateStatus = async () => {
    if (selectedParticipant) {
      const updatedParticipant = { ...selectedParticipant, status: "Rejected" };
      await updateStatus(
        selectedParticipant.participantID,
        updatedParticipant,
        userObj.token
      );
    }
    // Close the confirmation dialog
    setShowConfirmation(false);
  };

  // const images = data && data?.data?.images ? data?.data?.images : [];

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#27282C",
      color: "#fff",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#27282C",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#3a3b3f" : "#27282C",
      color: "#fff",
    }),
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event ? event.value : "All");
    setCurrentPage(1);
    setSelectedHostName("");
    setCurrentPage(1);
  };
  const updatedItemsPerPage = itemsPerPage;

  const handleHostName = (e) => {
    if (e && e.value) {
      setSelectedHostName(e.value);
    } else {
      setSelectedHostName("");
    }
  };

  const getFilteredParticipants = () => {
    const filteredParticipantsData = eventParticipantByUser.filter(
      (participant) => {
        const statusMatch =
          statusFilter === "All" ||
          participant.participant.status === statusFilter;

        const hostNameMatch =
          selectedHostName === "" ||
          participant.participant.fullName === selectedHostName;

        return statusMatch && hostNameMatch;
      }
    );

    const startIndex = (currentPage - 1) * updatedItemsPerPage;
    const endIndex = startIndex + updatedItemsPerPage;
    return filteredParticipantsData.slice(startIndex, endIndex);
  };

  const filteredParticipants = getFilteredParticipants();

  const updatedTotalPages = Math.ceil(
    eventParticipantByUser.length / updatedItemsPerPage
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const options = { month: "long", day: "numeric", weekday: "long" };

    return date.toLocaleDateString("en-US", options);
  };

  console.log(data, "DATA");

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // initialSlide: 0,
  };

  const loadMoreReview = () => {
    apiRequest.limit = 10;
    apiRequest.page = apiRequest.page + 1;
    callReviewApi(data?.data?.host, true);
  };

  const getReviewModal = () => {
    return (
      <>
        <Default>
          <Modal
            size="lg"
            show={reviewModal}
            onHide={handleClose}
            backdrop="static"
            dialogClassName="modal-dimensions borderandWidth"
            centered
          >
            <div className="ratingsCont">
              <div className="flexUpCont">
                <h2 className="profileTitsCom">
                  What guests say about {data?.data?.hostName}
                </h2>{" "}
                <button onClick={() => showReviewModal(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-x"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M18 6l-12 12" />
                    <path d="M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="ratingCardsCont modalOne">
                {allReviewData.reviews.map((review) => {
                  return (
                    <div className="ratingCard">
                      <div className="profileratings">
                        <div className="profImg">
                          <img src={review.profileImage} alt="" />
                        </div>
                        <div className="profNameNStar">
                          <h4>{review?.userName}</h4>

                          {showStarRating(review?.rating)}
                        </div>
                      </div>

                      <div className="ratingText">
                        <p>{review?.comment}</p>
                      </div>
                    </div>
                  );
                })}

                {showMore && (
                  <div className="d-flex justify-content-center">
                    <b
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={loadMoreReview}
                    >
                      View More
                    </b>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        </Default>

        <Mobile>
          <Modal
            size="lg"
            show={reviewModal}
            onHide={handleClose}
            backdrop="static"
            centered
          >
            <div className="ratingsCont">
              <div className="flexUpCont">
                <h2 className="profileTitsCom">
                  What guests say about {data?.data?.hostName}
                </h2>{" "}
                <button onClick={() => showReviewModal(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-x"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M18 6l-12 12" />
                    <path d="M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="ratingCardsCont modalOne">
                {allReviewData.reviews.map((review) => {
                  return (
                    <div className="ratingCard">
                      <div className="profileratings">
                        <div className="profImg">
                          <img src={review.profileImage} alt="" />
                        </div>
                        <div className="profNameNStar">
                          <h4>{review?.userName}</h4>

                          {showStarRating(review?.rating)}
                        </div>
                      </div>

                      <div className="ratingText">
                        <p>{review?.comment}</p>
                      </div>
                    </div>
                  );
                })}

                {showMore && (
                  <div className="d-flex justify-content-center">
                    <b
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={loadMoreReview}
                    >
                      View More
                    </b>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        </Mobile>
      </>
    );
  };

  if (isLoading) return getLoader();

  if (partyNotFount) {
    return <NotFoundPage text={"Party"} />;
  }

  return (
    <>
      {/* <Default> */}
      <div className="relative marMore">
        <Mobile>
          <div className="cutomBackButton" onClick={handleGoBack}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-left"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 6l-6 6l6 6" />
            </svg>
          </div>

          <Slider {...settings}>
            {data?.data?.images.map((logo) => {
              return (
                <div className="partyImageGrid single">
                  <img src={logo} />
                </div>
              );
            })}
          </Slider>
        </Mobile>
        <Container>
          <Row>
            <Col>
              <div className="partyHeading">
                <h1>{data?.data?.title}</h1>

                <div className="eventDetGap">
                  <div className="locationNdate flexCenter">
                    <div className="dateCont flexCenter">
                      <div className="iconSvg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-calendar-event"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                          <path d="M16 3l0 4" />
                          <path d="M8 3l0 4" />
                          <path d="M4 11l16 0" />
                          <path d="M8 15h2v2h-2z" />
                        </svg>
                      </div>
                      <p> {formatDate(data?.data?.eventDate)}</p>

                      <span className="line"></span>

                      <p>
                        {formatTime(data?.data?.startTime)} to{" "}
                        {formatTime(data?.data?.endTime)}
                      </p>
                    </div>
                    <div className="locationCont flexCenter">
                      <div className="iconSvg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-map-pin"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                          <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                        </svg>
                      </div>
                      <p>
                        {data?.data?.location}
                        {","} {data?.data?.city}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <Default>
                <div className="partyImagesCont">
                  {data?.data?.images.length == 1 ? (
                    <div className="partyImageGrid single">
                      <div>
                        <img src={data?.data?.images?.[0]} alt="" />
                      </div>
                    </div>
                  ) : (
                    <div className="partyImageGrid">
                      <div>
                        <img src={data?.data?.images?.[0]} alt="" />
                      </div>

                      <div className="workaroundFlex">
                        <img src={data?.data?.images?.[1]} alt="" />
                        <img src={data?.data?.images?.[2]} alt="" />
                      </div>
                    </div>
                  )}
                </div>
              </Default>

              <div className="hostedByCont">
                <h3 className="partyDetTit">Hosted By</h3>

                <div className="hostdetCont">
                  <div className="flexyMid">
                    <div
                      className="hostActDet"
                      onClick={() => {
                        history.push(
                          "/hostDetails/" +
                            data?.data?.host +
                            "/" +
                            data?.data?.eventId
                        );
                      }}
                    >
                      <div className="hostProf">
                        <img src={data?.data?.hostProfileImage} alt="" />
                      </div>

                      <h4>{data?.data?.hostName}</h4>
                    </div>

                    <div className="hostNums">
                      <h2>{data?.data?.partiesHosted}</h2>
                      <p>Parties hosted</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="partyInfo">
                {data?.data?.bookingStatus === 2 && (
                  <div className="soldOutViewOver">
                    <img src={soldOut} alt="" />
                  </div>
                )}
                <div className="flexyInfo">
                  <div className="infoIcon yellow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-armchair"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M5 11a2 2 0 0 1 2 2v2h10v-2a2 2 0 1 1 4 0v4a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-4a2 2 0 0 1 2 -2z" />
                      <path d="M5 11v-5a3 3 0 0 1 3 -3h8a3 3 0 0 1 3 3v5" />
                      <path d="M6 19v2" />
                      <path d="M18 19v2" />
                    </svg>
                  </div>
                  {/* <p>{`${data?.data?.acceptedParticipantsCount} / ${data?.data?.membersLimit} people already approved`}</p> */}
                  <p>
                    {(data?.data?.acceptedParticipantsCount /
                      data?.data?.membersLimit) *
                      100 >=
                    50
                      ? "LAST FEW SPOTS LEFT"
                      : "FILLING FAST"}
                  </p>
                </div>
                <div className="flexyInfo">
                  <div className="infoIcon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-users"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                      <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                      <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                      <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                    </svg>
                  </div>
                  <p>You can expect 7 - 15 people in the party</p>
                </div>

                {data?.data?.isShowRatio && (
                  <div className="flexyInfo">
                    <div className="infoIcon two">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-heart-handshake"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
                        <path d="M12 6l-3.293 3.293a1 1 0 0 0 0 1.414l.543 .543c.69 .69 1.81 .69 2.5 0l1 -1a3.182 3.182 0 0 1 4.5 0l2.25 2.25" />
                        <path d="M12.5 15.5l2 2" />
                        <path d="M15 13l2 2" />
                      </svg>
                    </div>
                    <p>
                      This party maintains at least a 60 : 40 male to female
                      ratio
                    </p>
                  </div>
                )}
              </div>

              {userObj?.userId !== data?.data?.host ? (
                <>
                  <About
                    title="About the Party"
                    content={`<p>${data?.data?.description.replace(
                      /\n/g,
                      "</p><p>"
                    )}</p>`}
                    type="party"
                    pageType="party-details"
                  />
                  <Suspense fallback={<p>Loading...</p>}>
                    {/* <Amenities /> */}
                    <Amenities amenities={data?.data?.amenities} />
                  </Suspense>
                  <Expander
                    title="House Rules"
                    data={`<p>${data?.data?.rules.replace(
                      /\n/g,
                      "</p><p>"
                    )}</p>`}
                  />
                  <Expander
                    title="How it works"
                    data="<p>A. Send a request to attend the house party.<br/>
  										B.  Process the payment and wait for the approval from the host.<br/>
  										C.  If your request is approved, you will receive the party details on your registered email. Simultaneously, you can also check the status of your request on your dashboard.<br/>
  										D.  If your request is not approved, you will receive a 100% refund within 5-7days.</p>"
                  />
                  <Expander
                    title="Cancellation Policy"
                    data="<p>A. 100% refund <br/>
											<p>&emsp; i.   If you cancel 48 hours before to the party start time.
											<br/>&emsp; ii.  If the host cancels the party</p>
										B. 50% refund if you cancel 24 hours before the party start time. <br/>
								C. No refunds if you cancel within 24 hours of the party start time. </p>"
                  />
                </>
              ) : (
                <div>
                  {/* Tab Headers */}
                  <div style={tabListStyle}>
                    <div
                      style={
                        activeTab === "guestDetails" ? activeTabStyle : tabStyle
                      }
                      onClick={() => handleTabClick("guestDetails")}
                    >
                      Guest Details
                    </div>
                    <div
                      style={
                        activeTab === "aboutParty" ? activeTabStyle : tabStyle
                      }
                      onClick={() => handleTabClick("aboutParty")}
                    >
                      About the Party
                    </div>
                  </div>

                  {/* Tab Content */}
                  <div style={tabContentStyle}>
                    {activeTab === "guestDetails" && (
                      <div>
                        {userObj?.userId === data?.data?.host && (
                          <div
                            style={{ marginTop: "20px" }}
                            className="guestDetConts"
                          >
                            <h3 className="guestTopTit">Guest Details</h3>
                            <div
                              className="guestcardGrid"
                              style={{ marginTop: "10px" }}
                            >
                              <div className="guestCardCont">
                                <div className=" mb-0 newguestInfoCard">
                                  <div className="d-flex justify-content-between">
                                    <div className="guestCardHeader">
                                      <p>Total Requests</p>
                                      <div>
                                        <h3>{eventParticipantByUser.length}</h3>
                                      </div>
                                    </div>
                                    <div className="iconShapeWear userBlue">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-users"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                                        <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                        <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                        <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="guestCardCont">
                                <div className=" mb-0 newguestInfoCard">
                                  <div className="d-flex justify-content-between">
                                    <div className="guestCardHeader">
                                      <p>Guests Approved</p>
                                      <div>
                                        <h3>{statusCounts}</h3>
                                      </div>
                                    </div>
                                    <div className="iconShapeWear thumbsupGreen">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-thumb-up"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3" />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="guestCardCont">
                                <div className=" mb-0 newguestInfoCard">
                                  <div className="d-flex justify-content-between">
                                    <div className="guestCardHeader">
                                      <p>Guests Disapproved</p>
                                      <div>
                                        <h3>{rejectCoun}</h3>
                                      </div>
                                    </div>
                                    <div className="iconShapeWear thumbsdownRed">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-thumb-down"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M7 13v-8a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v7a1 1 0 0 0 1 1h3a4 4 0 0 1 4 4v1a2 2 0 0 0 4 0v-5h3a2 2 0 0 0 2 -2l-1 -5a2 3 0 0 0 -2 -2h-7a3 3 0 0 0 -3 3" />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="guestCardCont">
                                <div className=" mb-0 newguestInfoCard">
                                  <div className="d-flex justify-content-between">
                                    <div className="guestCardHeader">
                                      <p>Guest Pending</p>
                                      <div>
                                        <h3>{pendingCoun}</h3>
                                      </div>
                                    </div>
                                    <div className="iconShapeWear guestPendingOrange">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-exclamation-circle"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                        <path d="M12 9v4" />
                                        <path d="M12 16v.01" />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="guestCardCont">
                                <div className=" mb-0 newguestInfoCard">
                                  <div className="d-flex justify-content-between">
                                    <div className="guestCardHeader">
                                      <p>Guests Refunded</p>
                                      <div>
                                        <h3>{refundCoun}</h3>
                                      </div>
                                    </div>
                                    <div className="iconShapeWear backYellow">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-back-up-double"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M13 14l-4 -4l4 -4" />
                                        <path d="M8 14l-4 -4l4 -4" />
                                        <path d="M9 10h7a4 4 0 1 1 0 8h-1" />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br />

                            <div className="relative">
                              <div className="littleBlackCont">
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "90px",
                                    padding: "5px",
                                  }}
                                  className="guestSearchCont"
                                >
                                  <div
                                    style={{ width: "50%" }}
                                    className="widthFifty"
                                  >
                                    <Select
                                      id="name"
                                      name="name"
                                      isSearchable
                                      onChange={(e) => {
                                        handleHostName(e);
                                      }}
                                      options={evnetHostName}
                                      placeholder="Search guests"
                                      isClearable
                                      styles={customSelectStyles}
                                    />
                                  </div>
                                  <div
                                    style={{ width: "50%" }}
                                    className="widthFifty"
                                  >
                                    <Select
                                      id="status"
                                      name="status"
                                      onChange={handleStatusFilterChange}
                                      isSearchable
                                      isClearable
                                      placeholder="Filter"
                                      options={evnetHostStatus}
                                      styles={customSelectStyles}
                                    />
                                  </div>
                                </div>
                                <table
                                  className="guestListTable"
                                  style={{
                                    borderCollapse: "collapse",
                                    width: "100%",
                                  }}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        style={tableHeaderStyle}
                                        className="textLeft borderLeftHalf"
                                      >
                                        Profile
                                      </th>
                                      <th style={tableHeaderStyle}>Gender</th>
                                      <th style={tableHeaderStyle}>
                                        Verification Status
                                      </th>
                                      <th
                                        style={tableHeaderStyle}
                                        className="borderRightHalf"
                                      >
                                        Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {getFilteredParticipants().map(
                                      (participant) => (
                                        <tr key={participant.id}>
                                          <td
                                            style={tableCellStyle}
                                            className="textLeft"
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                cursor: "pointer",
                                              }}
                                              onClick={(e) => {
                                                //   history.push('/guestDetails/'+participant.participantId)
                                                // }}
                                                history.push({
                                                  pathname:
                                                    "/guestDetails/" +
                                                    participant.participantId +
                                                    "/" +
                                                    id,
                                                  state: {
                                                    data: participant?.user
                                                      ?.userId,
                                                  },
                                                });
                                              }}
                                            >
                                              <img
                                                src={
                                                  participant?.user
                                                    ?.profileImage ||
                                                  (participant?.participant
                                                    ?.profileImage
                                                    ? participant.participant
                                                        .profileImage
                                                    : "https://playace.co/static/media/party.32bb88c9.png")
                                                }
                                                alt="User Profile"
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                  borderRadius: "50%",
                                                  marginRight: "10px",
                                                  cursor: "pointer",
                                                }}
                                              />

                                              {participant?.user
                                                ? participant.user?.fullName
                                                : participant.participant
                                                    .fullName}
                                              <br></br>
                                              {participant.participant.phone !==
                                                participant.orderDetails
                                                  .refererPhone &&
                                              participant.orderDetails.referer
                                                ? `(${participant.orderDetails.referer})`
                                                : ""}
                                            </div>
                                          </td>
                                          <td style={tableCellStyle}>
                                            {participant.participant.gender}
                                          </td>
                                          <td style={tableCellStyle}>
                                            {participant?.user
                                              ?.completionStatus &&
                                            participant?.user
                                              ?.completionStatus === true ? (
                                              <div className="verified">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  class="icon icon-tabler icons-tabler-outline icon-tabler-rosette-discount-check"
                                                >
                                                  <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                  />
                                                  <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1" />
                                                  <path d="M9 12l2 2l4 -4" />
                                                </svg>
                                                Verified
                                              </div>
                                            ) : (
                                              <div className="pending">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  class="icon icon-tabler icons-tabler-outline icon-tabler-exclamation-circle"
                                                >
                                                  <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                  />
                                                  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                                  <path d="M12 9v4" />
                                                  <path d="M12 16v.01" />
                                                </svg>
                                                Pending
                                              </div>
                                            )}
                                          </td>
                                          {participant.participant.status ===
                                          "Pending" ? (
                                            <td style={tableCellStyle}>
                                              <button
                                                type="button"
                                                class="btn btn-primary"
                                                data-toggle="modal"
                                                data-target=".bd-example-modal-sm"
                                                style={buttonStyle}
                                                onClick={() =>
                                                  showConfirmationDialog(
                                                    participant
                                                  )
                                                }
                                                // onClick={handleUpdateStatus(participant)}
                                              >
                                                Reject
                                              </button>
                                            </td>
                                          ) : (
                                            <td style={tableCellStyle}>
                                              {participant.participant
                                                .status === "Rejected" ? (
                                                <div className="userStatusBadge nApprove">
                                                  <span></span>
                                                  Disapproved
                                                </div>
                                              ) : (
                                                participant.participant.status
                                              )}
                                            </td>
                                          )}
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              {showConfirmation && (
                                <div
                                  className="modal show"
                                  style={{
                                    display: "block",
                                    position: "fixed",
                                    top: "75%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                  }}
                                >
                                  <Modal.Dialog>
                                    <Modal.Header>
                                      <Modal.Title>
                                        Are you sure you want to disapprove this
                                        guest?
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Footer>
                                      <button
                                        style={buttonStyle}
                                        onClick={handleUpdateStatus}
                                      >
                                        Yes
                                      </button>
                                      <button
                                        style={closeButtonStyle}
                                        onClick={() =>
                                          setShowConfirmation(false)
                                        }
                                      >
                                        Cancel
                                      </button>
                                    </Modal.Footer>
                                  </Modal.Dialog>
                                </div>
                              )}
                              {filteredParticipants.length > 0 &&
                              updatedTotalPages > 0 ? (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {Array.from({
                                    length: updatedTotalPages,
                                  }).map((_, index) => (
                                    <button
                                      key={index}
                                      style={{
                                        margin: "5px",
                                        padding: "5px 10px",
                                        border: "none",
                                        color:
                                          currentPage === index + 1
                                            ? "white"
                                            : "RGB(255 24 68)",
                                        background:
                                          currentPage === index + 1
                                            ? "RGB(255 24 68)"
                                            : "white",
                                        fontWeight:
                                          currentPage === index + 1
                                            ? "bold"
                                            : "normal",
                                      }}
                                      onClick={() => setCurrentPage(index + 1)}
                                    >
                                      {index + 1}
                                    </button>
                                  ))}
                                </div>
                              ) : (
                                <h4
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "10px",
                                    position: "absolute",
                                    bottom: "15px",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                  }}
                                >
                                  No Filtered Data
                                </h4>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {activeTab === "aboutParty" && (
                      <div>
                        <About
                          title="About the Party"
                          content={`<p>${data?.data?.description.replace(
                            /\n/g,
                            "</p><p>"
                          )}</p>`}
                          type="party"
                          pageType="party-details"
                        />
                        <Suspense fallback={<p>Loading...</p>}>
                          {/* <Amenities /> */}
                          <Amenities amenities={data?.data?.amenities} />
                        </Suspense>
                        <Expander
                          title="House Rules"
                          data={`<p>${data?.data?.rules.replace(
                            /\n/g,
                            "</p><p>"
                          )}</p>`}
                        />
                        <Expander
                          title="How it works"
                          data="<p>A. Send a request to attend the house party.<br/>
  										B.  Process the payment and wait for the approval from the host.<br/>
  										C.  If your request is approved, you will receive the party details on your registered email. Simultaneously, you can also check the status of your request on your dashboard.<br/>
  										D.  If your request is not approved, you will receive a 100% refund within 5-7days.</p>"
                        />
                        <Expander
                          title="Cancellation Policy"
                          data="<p>A. 100% refund <br/>
											<p>&emsp; i.   If you cancel 48 hours before to the party start time.
											<br/>&emsp; ii.  If the host cancels the party</p>
										B. 50% refund if you cancel 24 hours before the party start time. <br/>
								C. No refunds if you cancel within 24 hours of the party start time. </p>"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {parseInt(reviewData?.totalCount) != 0 && (
                <div className="ratingsHostCont">
                  <div className="cards-app-container">
                    <div className="cards-list-container">
                      <h1 className="heading">
                        What guests say about {data?.data?.hostName}
                      </h1>
                      <div className="header-rating-container">
                        <img
                          src={data?.data?.hostProfileImage}
                          alt="User"
                          className="header-image"
                        />
                        <h3 className="ratings-count">
                          {reviewData?.ratingAverage}
                        </h3>

                        {showStarRating(reviewData?.ratingAverage)}

                        <button
                          className="see-all-button"
                          onClick={() => {
                            showReviewModal(true);
                            apiRequest.limit = 10;
                            apiRequest.page = 1;
                            setAllReviewData({
                              reviews: [],
                              totalCount: 0,
                              totalPages: 0,
                              ratingAverage: 0,
                            });
                            callReviewApi(data?.data?.host, true);
                          }}
                        >
                          See all
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-up-right"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M17 7l-10 10" />
                            <path d="M8 7l9 0l0 9" />
                          </svg>
                        </button>
                      </div>
                      <ul className="cards-list">
                        {reviewData?.reviews?.map((eachCard) => (
                          <ReviewItem
                            cardDetails={eachCard}
                            key={eachCard.id}
                          />
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </Col>

            {userObj?.userId !== data?.data?.host && (
              <Col lg={4} md={5}>
                <Default>
                  <BookTicket
                    isAuthenticated={isAuthenticated}
                    userObj={userObj}
                    party={data}
                    // sold={data?.data?.event_sold_out}
                    isLoading={isLoading}
                    ref={bookTicketRef}
                  />
                </Default>
                <Mobile>
                  <BookTicket
                    isAuthenticated={isAuthenticated}
                    userObj={userObj}
                    party={data}
                    // sold={data?.data?.event_sold_out}
                    isLoading={isLoading}
                    hideCard={true}
                    ref={bookTicketRef}
                  />
                </Mobile>
              </Col>
            )}
          </Row>

          <p style={{ height: 10 }} />

          <AdBanners isEvent={true} />

          {userObj.userId !== data?.data?.host && (
            <Attend
              title="Discover Similar House Parties"
              currentEvent={data}
              button={false}
            />
          )}
          <div className="my-5" />
          {/* <CTA /> */}
        </Container>
      </div>

      {getReviewModal()}

      {/* </Default> */}
    </>
  );
}

const ReviewItem = ({ cardDetails }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const { userName, comment, profileImage } = cardDetails;

  const truncatedComment =
    comment.length > 100 ? comment.slice(0, 100) + "..." : comment;

  return (
    <li className={`card-item`}>
      {/* <p className="card-description">{comment}</p> */}

      <p className={`card-description ${isExpanded ? "expanded" : ""}`}>
        {truncatedComment}
      </p>

      <div className="img-container">
        <img className="card-img" src={profileImage} alt={userName} />
        <p className="card-name">{userName}</p>
      </div>
    </li>
  );
};

const Rating = ({ value, onChange }) => {
  return (
    <div className="rating-container">
      {[1, 2, 3, 4, 5].map((star) => (
        <span
          key={star}
          className={`star ${value >= star ? "filled" : ""}`}
          onClick={() => onChange(star)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="icon icon-tabler icons-tabler-filled icon-tabler-star"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M8.243 7.34l-6.38 .925l-.113 .023a1 1 0 0 0 -.44 1.684l4.622 4.499l-1.09 6.355l-.013 .11a1 1 0 0 0 1.464 .944l5.706 -3l5.693 3l.1 .046a1 1 0 0 0 1.352 -1.1l-1.091 -6.355l4.624 -4.5l.078 -.085a1 1 0 0 0 -.633 -1.62l-6.38 -.926l-2.852 -5.78a1 1 0 0 0 -1.794 0l-2.853 5.78z" />
          </svg>
        </span>
      ))}
    </div>
  );
};
